import { useCallback } from 'react'

import useAppDispatch from '../../../store/hooks/useAppDispatch'
import useAppStore from '../../../store/hooks/useAppStore'
import { clientContextCompanyIdByCompanyMemberIdSelector } from '../../clientContext/store/clientContextSelectors'
import { checkoutCurrentCheckoutDetailsSelector } from '../store/checkoutSelectors'
import { updateCheckout } from '../store/checkoutThunks'

export default function useUpdateCheckoutCompanyId() {
  const store = useAppStore()

  const dispatch = useAppDispatch()

  const handleUpdateCheckoutCompany = useCallback(
    (companyMemberId: string | null) => {
      const currentCheckout = checkoutCurrentCheckoutDetailsSelector(
        store.getState(),
      )

      const companyId = companyMemberId
        ? clientContextCompanyIdByCompanyMemberIdSelector(
            store.getState(),
            companyMemberId,
          )
        : null

      if (currentCheckout) {
        const checkoutCompanyId = currentCheckout.company?.id ?? null
        const didCompanyChange = companyId !== checkoutCompanyId

        if (didCompanyChange) {
          // Update checkout with new company ID
          dispatch(updateCheckout({ companyId }))
        }
      }
    },
    [dispatch, store],
  )

  return handleUpdateCheckoutCompany
}
