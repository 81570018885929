/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit'
import {
  CheckoutItemStatusUpdatedEvent,
  CheckoutPreptimeUpdatedEvent,
  CheckoutStatusUpdatedEvent,
  CheckoutStatus,
  CheckoutMetaDataItemData,
  CheckoutMetaDataType,
  CheckoutDeliveryStatusUpdatedEvent,
  OrderFormat,
  CheckoutDeliveryStatus,
} from '@ancon/wildcat-types'

import {
  CheckoutFilters,
  CheckoutReducerState,
  CheckoutTipModalType,
  CheckoutTipState,
} from '../types'
import getCheckoutFilters from '../utils/getCheckoutFilters'

const checkoutReducers = {
  checkoutSetCheckoutFilters(
    state: CheckoutReducerState,
    action: PayloadAction<Partial<CheckoutFilters>>,
  ) {
    const { payload } = action
    state.checkoutFilters = getCheckoutFilters(state.checkoutFilters, payload)
  },
  setSelectedCheckoutItemId(
    state: CheckoutReducerState,
    action: PayloadAction<string | null>,
  ) {
    state.currentCheckoutSelectedCheckoutItemId = action.payload
  },
  checkoutSetIsVisibleMinimumOrderModal(
    state: CheckoutReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isMinimumOrderModalVisible = action.payload
  },
  clearCurrentCheckout(state: CheckoutReducerState) {
    state.currentCheckoutId = null
    state.currentCheckoutDetails = null
  },
  checkoutSetIsVisibleDiscountModal(
    state: CheckoutReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isDiscountModalVisible = action.payload
  },
  checkoutSetTipModalVisibleType(
    state: CheckoutReducerState,
    action: PayloadAction<CheckoutTipModalType>,
  ) {
    state.checkoutTip.visibleModalType = action.payload
  },
  checkoutSetIsVisibleCustomerInfoModal(
    state: CheckoutReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isCustomerInfoModalVisible = action.payload
  },
  checkoutSetIsVisibleDeleteOrderModal(
    state: CheckoutReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isVisibleDeleteOrderModal = action.payload
  },
  checkoutClearCheckoutSummary(state: CheckoutReducerState) {
    state.checkoutSummary = null
    state.checkoutSummaryOutletListItem = null
    state.checkoutSummaryFetchPending = false
    state.checkoutStatusLastUpdatedAt = 0
  },
  checkoutEmitStatusUpdatedEvent(
    state: CheckoutReducerState,
    action: PayloadAction<CheckoutStatusUpdatedEvent>,
  ) {
    function handleCheckoutStatusEvent(
      key: 'checkoutSummary' | 'currentCheckoutDetails',
    ) {
      const {
        checkoutId,
        status,
        timestamp,
        serviceTime,
        cancelInfo,
        preparationTime,
        ticketId,
        orderId,
      } = action.payload

      if (state[key] && checkoutId === state[key]!.id) {
        let timestampDateValue = new Date(timestamp).valueOf()

        if (Number.isNaN(timestampDateValue)) {
          timestampDateValue = 0
        }

        if (
          !Number.isNaN(status) &&
          timestampDateValue >= state.checkoutStatusLastUpdatedAt
        ) {
          state[key]!.status = status
          state.checkoutStatusLastUpdatedAt = timestampDateValue

          if (
            status === CheckoutStatus.Captured ||
            status === CheckoutStatus.InProgress
          ) {
            // Update service time - otherwise there can be difference with POS and AOW
            state[key]!.serviceTime.time = serviceTime?.time || timestamp
          }
        }

        if (cancelInfo) {
          state[key]!.cancelInfo = cancelInfo
        }

        if (preparationTime) {
          state[key]!.tickets[0] = {
            ...state[key]!.tickets[0],
            preparationTime,
          }
        }

        if (ticketId) {
          state[key]!.tickets[0] = {
            ...state[key]!.tickets[0],
            id: ticketId,
          }
        }

        /** When checkout status changing from Authorized -> Captured, orderId should be set */
        if (!!orderId && !state[key]!.orderId) {
          state[key]!.orderId = orderId
        }
      }
    }

    handleCheckoutStatusEvent('checkoutSummary')
    // Also update the current checkout details
    handleCheckoutStatusEvent('currentCheckoutDetails')
  },
  checkoutEmitPreptimeUpdatedEvent(
    state: CheckoutReducerState,
    action: PayloadAction<CheckoutPreptimeUpdatedEvent>,
  ) {
    if (
      state.checkoutSummary &&
      action.payload.checkoutId === state.checkoutSummary.id
    ) {
      if (state.checkoutSummary.tickets[0]) {
        if (action.payload.preparationTime) {
          state.checkoutSummary.tickets[0].preparationTime =
            action.payload.preparationTime
        }
      }
    }
  },
  checkoutItemStatusUpdatedEvent(
    state: CheckoutReducerState,
    action: PayloadAction<CheckoutItemStatusUpdatedEvent>,
  ) {
    if (
      state.checkoutSummary &&
      action.payload.checkoutId === state.checkoutSummary.id
    ) {
      state.checkoutSummary.items.forEach(item => {
        if (item.id === action.payload.checkoutItemId) {
          item.ticketItem = {
            ...item.ticketItem,
            ticketItemId: action.payload.ticketItemId,
            status: action.payload.ticketItemStatus,
          }
        }
      })
    }
  },
  checkoutAddPaymentAttempt(state: CheckoutReducerState) {
    state.checkoutPaymentAttempt += 1
  },
  checkoutUpdateCheckoutSummaryStatus(
    state: CheckoutReducerState,
    action: PayloadAction<{ status: CheckoutStatus }>,
  ) {
    if (state.checkoutSummary) {
      state.checkoutSummary.status = action.payload.status
    }
  },
  checkoutSetIsVisibleBoxPickupScheduleOrderModal(
    state: CheckoutReducerState,
    action: PayloadAction<boolean>,
  ) {
    state.isVisibleBoxPickupScheduleOrderModal = action.payload
  },
  checkoutUpdatePenguinLockersMetaData(
    state: CheckoutReducerState,
    action: PayloadAction<Partial<CheckoutMetaDataItemData>>,
  ) {
    const { payload } = action

    if (state.currentCheckoutDetails) {
      const newMetaData = state.currentCheckoutDetails.metaData.map(
        metaData => {
          if (
            metaData.metaDataType ===
            CheckoutMetaDataType.PenguinLockersMetaData
          ) {
            return {
              ...metaData,
              metaData: {
                ...metaData.metaData,
                ...payload,
              },
            }
          }

          return metaData
        },
      )
      state.currentCheckoutDetails.metaData = newMetaData
    }
  },
  checkoutDeliveryStatusUpdatedEvent(
    state: CheckoutReducerState,
    action: PayloadAction<CheckoutDeliveryStatusUpdatedEvent>,
  ) {
    const { checkoutId, newDeliveryStatus } = action.payload
    const { checkoutSummary } = state
    const deliveryStatus = checkoutSummary?.deliveryStatus ?? null

    if (
      checkoutSummary &&
      checkoutSummary.id === checkoutId &&
      (deliveryStatus === null || newDeliveryStatus > deliveryStatus)
    ) {
      checkoutSummary.deliveryStatus = newDeliveryStatus

      const isPickedUp = newDeliveryStatus === CheckoutDeliveryStatus.PickedUp
      const isBoxPickup = checkoutSummary.orderFormat === OrderFormat.BoxPickup

      // If the order is a box pickup and the status is picked up, set the checkout status to done
      if (isBoxPickup && isPickedUp) {
        checkoutSummary.status = CheckoutStatus.Done
      }
    }
  },
  checkoutUpdateTipState(
    state: CheckoutReducerState,
    action: PayloadAction<Partial<CheckoutTipState>>,
  ) {
    const { payload } = action

    state.checkoutTip = {
      ...state.checkoutTip,
      ...payload,
    }
  },
}

export default checkoutReducers
